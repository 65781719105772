.usage-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 20px 10px;
    /*max-height: 120px;*/
    /*overflow-y: auto;*/
    /*width: 100%;*/
    padding-right: 10px;
}

.usage-content > .name {
}

@media only screen and (max-width: 768px) {
    .usage-content {
        max-height: unset;
    }
}

.usage-line {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    grid-column-gap: 4px; /* Adjust the value to your preference */
    /*padding: 2px 10px;*/
}

.usage-line > :last-child {
    justify-self: end;
}

.usage-line:not(:last-child) {
    border-bottom: 1px solid var(--border-color); /* Adjust color and thickness as needed */
    padding-bottom: 10px; /* Optional: Adds some space between the text and the line */
    margin-bottom: 10px; /* Optional: Adds some space between the line and the next item */
}

/* Add alternating background colors */
/*.usage-line:nth-child(even) {*/
/*    background-color: var(--bg-secondary-color-2); !* Light color for odd items *!*/
/*}*/

/*.usage-line:nth-child(even) {*/
/*    !*background-color: #e9e9e9; !* Slightly darker color for even items *!*!*/
/*}*/

.ranking-line {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    grid-column-gap: 4px; /* Adjust the value to your preference */
}
.ranking-line > :last-child {
    justify-self: end;
}

.ranking-line > :nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ranking-line  img {
    height: 20px;
    width: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
